<template>
  <section>
    <!--<pm-Breadcrumb :home="home" :model="items_bread" />-->

    <div class="row">
      <div class="col-md-7">
        <h2 class="titulo-links-escolha">Configuracão de Logos</h2>
      </div>
      <div class="col-md-5">
        <pm-Button
          :disabled="!situacaoAno"
          type="button"
          style="float: right; margin-top: 30px"
          @click="
            showConfig = !showConfig;
            ident = 0;
          "
          label="CADASTRAR NOVO LOGO"
          class="p-button-success"
        ></pm-Button>
      </div>
    </div>

    <div class="pm-card">
      <pm-DataTable
        :value="logosALL"
        dataKey="id"
        :rowHover="true"
        filterDisplay="menu"
        v-model:filters="filters"
        responsiveLayout="scroll"
      >
        <pm-Column
          field="tipo"
          sortable
          header="Local"
          style="font-size: 14px; vertical-align: middle !important"
        >
          <template #body="{ data }">
            <span v-if="data.tipo == 0">
              Login de accesso do Painel Administrativo</span
            >
            <span v-if="data.tipo == 1"> Topo do Painel Administrativo</span>
            <span v-if="data.tipo == 3">Login Imagem de Fundo</span>
          </template>
        </pm-Column>

        <pm-Column
          field="logo"
          sortable
          header="Logo"
          style="font-size: 14px; vertical-align: middle !important"
        >
          <template #body="{ data }">
            <div v-if="data.logo">
              <img
                :src="pathDoSite + 'storage/' + data.logo"
                style="
                  width: 100%;
                  height: 150px;
                  aspect-ratio: 3 / 2;
                  object-fit: contain;
                "
              />
            </div>
          </template>
        </pm-Column>

        <pm-Column
          field="tipo"
          header=""
          style="
            font-size: 14px;
            vertical-align: middle !important;
            text-align: right;
          "
        >
          <template #body="{ data }">
            <pm-Button
              :disabled="!situacaoAno"
              label="Editar"
              icon="pi pi-pencil"
              @click="
                showConfig = !showConfig;
                ident = 1;
                tipo = data.tipo;
                showLogo();
              "
              style="margin-right: 8px"
            />

            <pm-Button
              :disabled="!situacaoAno"
              label="Deletar"
              class="p-button-danger"
              icon="pi pi-trash"
              @click="deletarLogo(data.tipo)"
            />
          </template>
        </pm-Column>
      </pm-DataTable>
    </div>

    <pm-Dialog
      header="Configurar logoTipo"
      v-model:visible="showConfig"
      :breakpoints="{ '500px': '75vw', '400px': '90vw' }"
      :style="{ width: '50vw' }"
      :maximizable="true"
    >
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-8 lg:col-8" v-if="ident == 0">
          <label>Selecione o local do logoTipo</label>
          <select
            class="p-inputtext p-component"
            v-model="logo.tipo"
            style="appearance: revert !important; width: 100% !important"
          >
            <option value="" disabled selected>-- Escolha um Local --</option>
            <option :value="0">
              Login de accesso do Painel Administrativo
            </option>
            <option :value="1">Topo do Painel Administrativo</option>
            <option :value="3">Login Imagem de Fundo</option>
          </select>
        </div>
        <div v-if="ident == 1">
          <label>Deseja alterar este logo?</label>
          <div v-if="logotemp">
            <img
              :src="pathDoSite + 'storage/' + logotemp.logo"
              style="
                width: 250px;
                height: 110px;
                aspect-ratio: 3 / 2;
                object-fit: contain;
              "
            />
          </div>
          <br />
        </div>
        <div class="field col-12 md:col-12 lg:col-12">
          <label for="arquivo">Selecione o Logo:</label>
          <va-file-upload
            v-model="logo.logo"
            type="single"
            file-types=".png,.PNG,.jpg,.JPG,.jpeg,.JPEG"
          >
          </va-file-upload>
        </div>
      </div>

      <template #footer>
        <pm-Button label="Cadastrar" v-if="ident == 0" @click="SalvarLogo(0)" />

        <pm-Button label="Editar" v-else @click="SalvarLogo(1)" />

        <pm-Button
          label="Fechar"
          class="p-button-danger"
          icon=""
          @click="fecharModal()"
        />
      </template>
    </pm-Dialog>

    <pm-Toast />
  </section>
</template>

<script>
import { defineComponent } from "vue";
import axios from "axios";
import { Configuracao } from "@/class/configuracao.js";

export default defineComponent({
  components: {},
  data() {
    return {
      home: { icon: "pi pi-home", to: "#" },
      items_bread: [{ label: "Configuração de Logo" }],
      showConfig: false,
      ident: 0,
      logosALL: [],
      logotemp: [],
      logo: [],
      tipo: null,
      pathDoSite: axios.defaults.baseURL.replace("api/v1", ""),
      situacaoAno: 0,
    };
  },
  beforeMount() {
    this.situacaoAno = parseInt(sessionStorage.getItem("anoSitucao"));
    this.Logos();
  },
  methods: {
    fecharModal() {
      this.logo = [];
      this.logotemp = [];
      this.showConfig = false;
    },
    async SalvarLogo(t) {
      try {
        // t = 0  cadastrar  ;  t = 1 editar
        if (!this.logo.logo[0]) {
          this.$toast.add({
            severity: "error",
            summary: "É Necessario passar a imagem do logoTipo!",
            life: 3000,
          });
          return false;
        }
        const formData = new FormData();
        formData.append("logo", this.logo.logo[0]);
        const res = await Configuracao.gravacaoLogo(t, this.logo.tipo, formData);
        if (t == 0) {
          this.$toast.add({
            severity: "success",
            summary: "Logo cadastrado com Sucesso!",
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "success",
            summary: "Logo alterado com Sucesso!",
            life: 3000,
          });
        }
        this.logo = [];
        this.showConfig = false;
        this.Logos();
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: error.response.data.erro,
          life: 3000,
        });
      }
    },
    async showLogo() {
      this.logo = [];
      const dt = await Configuracao.allLogoShow(this.tipo);
      //this.logo = dt.data;
      this.logotemp = dt.data;
      this.logo.tipo = this.logotemp.tipo;
    },
    async Logos() {
      this.logosALL = [];
      const dt = await Configuracao.allLogo();
      this.logosALL = dt.data;
    },
    async deletarLogo(tipo) {
      try {
        const res = await Configuracao.deleteLogo(tipo);
        this.$toast.add({
          severity: "success",
          summary: "Logo Deletado com Sucesso!",
          life: 3000,
        });
        this.Logos();
      } catch (error) {
        console.log(error);
        this.$toast.add({
          severity: "error",
          summary: "Ocorreu um ERRO!",
          life: 3000,
        });
      }
    },
  },
});
</script>

<style>
.va-button--normal {
  border-radius: 5px !important;
}
</style>
